const linear = function (size, impact) {
  let evPerUnit = impact.per_unit || 1;
  let evVal = impact.value || 0; //+ (evaluation.yearly || 0) * (this.solution.timeline-this.solution.scenario.startYear)
  return (size * evVal) / evPerUnit;
};

const percentagePerPercentage = function (percentage, baseline, impact) {
  return (
    (((baseline * (impact.value || 1)) / 100) * percentage) /
    (impact.percentage_covered || 1)
  );
};

const percentagePerUnit = function (size, baseline, impact) {
  return (size * baseline * impact.value) / 100 / (impact.per_unit || 1);
};

// @TODO refactor using common calculation method
// const calculate = function(sum, indicator, baseline, deployedNbs, totalArea, objectTypes, percentageCallback, legacyPercentageString) {
// 	for (let type in deployedNbs) {
// 		let impact = objectTypes[type].impact[indicator] ? objectTypes[type].impact[indicator] : {}
// 		if (deployedNbs.hasOwnProperty(type) && Number(impact.value)) {
// 			let size = deployedNbs[type].size * (impact.area_covered || 1)
// 			if (impact.type == 'linear') {
// 				if (impact.direction === 'increase') {
// 					sum += linear(size, impact)
// 				} else {
// 					sum -= linear(size, impact)
// 				}
// 			}

// 			else if (impact.type == 'percentage' || impact.type == legacyPercentageString) {
// 				let value = percentageCallback(size, baseline, impact, totalArea);
// 				if (impact.direction === 'increase') {
// 					sum += value
// 				}
// 				if (impact.direction === 'decrease') {
// 					sum -= value
// 				}
// 			}
// 		}
// 	}
// 	return sum;
// }

const average = function (
  indicator,
  baseline,
  deployedNbs,
  totalArea,
  objectTypes
) {
  var sum = baseline * totalArea;
  for (let type in deployedNbs) {
    let impact = objectTypes[type].impact[indicator]
      ? objectTypes[type].impact[indicator]
      : {};
    if (deployedNbs.hasOwnProperty(type) && Number(impact.value)) {
      let size = deployedNbs[type].size * (impact.area_covered || 1);
      if (impact.type == "linear") {
        if (impact.direction === "increase") {
          sum += linear(size, impact);
        } else {
          sum -= linear(size, impact);
        }
      } else if (
        impact.type == "percentage" ||
        impact.type == "percentagePerUnit"
      ) {
        let value = percentagePerUnit(size, baseline, impact);
        if (impact.direction === "increase") {
          sum += value;
        }
        if (impact.direction === "decrease") {
          sum -= value;
        }
      }
    }
  }

  let result = sum / totalArea;
  if (parseInt(result) === parseFloat(result)) {
    return result;
  } else if (result < 1) {
    return parseFloat(result.toFixed(3));
  } else {
    return parseFloat(result.toFixed(1));
  }
};

const percentage = function (
  indicator,
  baseline,
  deployedNbs,
  totalArea,
  objectTypes
) {
  var sum = baseline;

  for (let type in deployedNbs) {
    let impact = objectTypes[type].impact[indicator] || {};
    if (!impact.value) impact.value = 1; // @todo fix data
    if (deployedNbs.hasOwnProperty(type) && Number(impact.value)) {
      let percentage =
        ((deployedNbs[type].size * (impact.area_covered || 1)) / totalArea) *
        100;

      if (impact.type == "linear" || !impact.type) {
        if (impact.direction === "increase") {
          sum += percentage;
        }
        if (impact.direction === "decrease") {
          sum -= percentage;
        }
      }

      if (
        impact.type == "percentage" ||
        impact.type == "percentagePerPercentage"
      ) {
        let percentage = deployedNbs[type].size / totalArea;
        if (impact.direction === "increase") {
          sum += percentagePerPercentage(percentage * 100, baseline, impact);
        } else {
          sum -= percentagePerPercentage(percentage * 100, baseline, impact);
        }
      }
    }
  }

  let result = sum > 100 ? 100 : sum;
  return parseInt(result) === parseFloat(result)
    ? result
    : parseFloat(result.toFixed(1));
};

const absolute = function (
  indicator,
  baseline,
  deployedNbs,
  totalArea,
  objectTypes
) {
  var sum = baseline;

  for (let type in deployedNbs) {
    let impact = objectTypes[type].impact[indicator]
      ? objectTypes[type].impact[indicator]
      : {};
    if (deployedNbs.hasOwnProperty(type) && Number(impact.value)) {
      if (impact.type == "linear") {
        if (impact.direction === "increase") {
          sum += linear(deployedNbs[type].size, impact);
        } else {
          sum -= linear(deployedNbs[type].size, impact);
        }
      }

      if (impact.type == "percentage") {
        if (impact.direction === "increase") {
          sum += percentagePerUnit(deployedNbs[type].size, baseline, impact);
        } else {
          sum -= percentagePerUnit(deployedNbs[type].size, baseline, impact);
        }
      }
    }
  }

  if (parseInt(sum) === parseFloat(sum)) {
    return sum;
  } else {
    return parseFloat(sum).toFixed(1);
  }
};

module.exports = {
  percentage,
  average,
  absolute,
};
